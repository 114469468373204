body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-list-select {
	display: inline-block;
	position: relative;
	outline: none;
}

.react-list-select--item {
	padding: 0.3em 0.6em;
	cursor: pointer;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.react-list-select--item.is-selected {
	-webkit-user-select: inherit;
	-moz-user-select: inherit;
	-ms-user-select: inherit;
	-o-user-select: inherit;
	user-select: inherit;
}

.react-list-select--item.is-disabled {
	cursor: default;
}

ul.react-list-select {
  list-style: none !important;
  /* list-style-type: none; */
	padding: 0;
	margin: 0;
}

body {
	padding: 3em;
}

.demo > div {
	display: inline-block;
	vertical-align: top;
	margin-right: 1.5em;
}

/* Add some style to the list */

.react-list-select {
	/* background: #fafafa; */
	/* border: 1px solid #cacaca; */
	margin-right: 0.5em;
}
.react-list-select--item.is-selected {
	background: #1abc9c;
}
.react-list-select:focus .react-list-select--item.is-focused::before,
.react-list-select:focus .react-list-select--item.is-focused::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 2px;
	background: #0d5c4c;
}
.react-list-select:focus .react-list-select--item.is-focused::before {
	left: 0;
}
.react-list-select:focus .react-list-select--item.is-focused::after {
	right: 0;
}
.react-list-select--item.is-disabled {
	color: #afafaf;
}

.context-menu .react-list-select {
	padding: 0.3em 0;
}
.context-menu .react-list-select--item {
	font-size: 0.8125em;
}

/* Component in list - last example */

.contact {
	padding: 0.5em;
}
.contact .email {
	font-size: 0.675em;
	color: #999;
}
