.App-header {
    font-size: calc(10px + 2vmin);
    color: #282c34;
}

.App-link {
    color: #61dafb;
}

.color-brand {
    color: #DE7E69;
}